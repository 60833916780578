import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService, DeviceManagerService, ElectronService, SIP_STATUS, SipService } from './core/services';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '../environments/environment';
import { Router } from '@angular/router';
import { SettingsFacade } from './core/store/facade';
import { Logger, LoggerService } from './core/services/logger';
import { BrowserNotificationService, NotificationService } from './core/services/notifications';
import { ConfigModel, ContextMenuActions, ContextMenuData, IPC_CHANNELS } from '../../electron-utils/electron.model';
import { UtilsService } from './shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReconnectionModalComponent } from './shared/components/reconnection-modal/reconnection-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public message: string;
  public logger: Logger;

  private isReconnectionModalOpen: boolean = false;

  constructor(
    private loggerService: LoggerService,
    private electronService: ElectronService,
    private translate: TranslateService,
    private router: Router,
    private sipService: SipService,
    private modalService: NgbModal,
    private configService: ConfigService,
    private settingsFacade: SettingsFacade,
    private deviceService: DeviceManagerService,
    private utilsService: UtilsService,
    private notificationService: NotificationService
  ) {
    this.translate.setDefaultLang('en');
    this.logger = loggerService.getLoggerInstance('AppComponent');

    this.logger.verbose('AppConfig', AppConfig);
    const config = this.configService.config;
    if (electronService.isElectron) {
      this.redirectToWindow();
      this.logger.verbose('Run in electron');
      // setup audio device as getted from config or get defaults
      // Set the language stored in config
      this.logger.debug(config);
      electronService.ipcRenderer.on(IPC_CHANNELS.OPEN_SETTINGS, () => {
        if (!router.url.includes('loading') && !router.url.includes('login')) {
          this.router.navigate(['home', 'settings', 'profile']);
        }
      })
    } else {
      this.logger.verbose('Run in browser');
      this.logger.debug(config);

    }
    this.translate.use(config.language);
  }

  ngOnInit(): void {
    if(this.electronService.isElectron) {
      this.electronService.ipcRenderer.on(IPC_CHANNELS.UPDATE_ERROR, () => {
        this.notificationService.error("common.errors.update-error");
      });
    }
    window.addEventListener('contextmenu', (event: MouseEvent) => {
      event.preventDefault(); // avoid to select whole string when right clicking
      const htmlElement = document.elementFromPoint(event.x, event.y);
      const contextMenuType = this.utilsService.getContextMenuType(event);
      const textSelected = this.utilsService.getTextSelected();
      const contextMenuData: ContextMenuData = {
        hasSelection: Boolean(textSelected),
        textData: textSelected
      }
      

      this.electronService.ipcRenderer.send(IPC_CHANNELS.OPEN_CONTEXT_MENU, contextMenuType, contextMenuData);
      this.electronService.ipcRenderer.once(IPC_CHANNELS.SELECTED_CONTEXT_MENU_OPTION, (event, ...args) => {
        const data: ContextMenuData = args[0];
        const el = (htmlElement as HTMLInputElement);
        switch(data.action) {
          case ContextMenuActions.paste:
            el.value = this.utilsService.pasteInPosition(el.value, data.textData, el.selectionStart, el.selectionEnd);
            htmlElement.dispatchEvent(new Event('input', {bubbles: true}));
            break;
          case ContextMenuActions.cut:
            el.value = this.utilsService.pasteInPosition(el.value, '', el.selectionStart, el.selectionEnd);
            htmlElement.dispatchEvent(new Event('input', {bubbles: true}));
        }

        this.electronService.ipcRenderer.send(IPC_CHANNELS.OPEN_CONTEXT_MENU, contextMenuType, contextMenuData);
        this.electronService.ipcRenderer.once(IPC_CHANNELS.SELECTED_CONTEXT_MENU_OPTION, (event, ...args) => {
          const data: ContextMenuData = args[0];
          const el = (htmlElement as HTMLInputElement);
          switch (data.action) {
            case ContextMenuActions.paste:
              el.value = this.utilsService.pasteInPosition(el.value, data.textData, el.selectionStart, el.selectionEnd);
              htmlElement.dispatchEvent(new Event('input', { bubbles: true }));
              break;
            case ContextMenuActions.cut:
              el.value = this.utilsService.pasteInPosition(el.value, '', el.selectionStart, el.selectionEnd);
              htmlElement.dispatchEvent(new Event('input', { bubbles: true }));
          }
        });
      });
    });

    this.deviceService.enumerateAudioDevices().then((devices) => {
      const config = this.configService.config;
      const audioInputDevices = devices.filter(el => el.kind === 'audioinput');
      const audioOutputDevices = devices.filter(el => el.kind === 'audiooutput');
      let filteredDevices = {
        inputs: this.getCurrentDevice(audioInputDevices, config, 'audio_input_device'),
        outputs: this.getCurrentDevice(audioOutputDevices, config, 'audio_output_device'),
        rings: this.getCurrentDevice(audioOutputDevices, config, 'audio_ring_device')
      };
      this.settingsFacade.setAudioInputDevice(filteredDevices.inputs);
      this.settingsFacade.setAudioOutputDevice(filteredDevices.outputs);
      this.settingsFacade.setAudioRingDevice(filteredDevices.rings);
    });


    this.sipService.sipStatus$.subscribe((status: SIP_STATUS) => {
      if(status === SIP_STATUS.FAILED_TO_RECONNECT && !this.isReconnectionModalOpen) {
        const modalRef = this.modalService.open(ReconnectionModalComponent, {
          size: 'l',
          backdrop: 'static',
          keyboard: false,
          centered: true
        });
        this.isReconnectionModalOpen = true;
        modalRef.hidden.subscribe(() => {
          this.isReconnectionModalOpen = false;
        });
      }
    })
  }

  private getCurrentDevice(devices: MediaDeviceInfo[], config: ConfigModel, property: string) {
    let result: MediaDeviceInfo;
    if (config?.settings?.audio?.[property]) {
      const device: string = (<string>config?.settings?.audio?.[property]);
      result = devices.find(el => el.label === JSON.parse(device).label);
    } else {
      result = devices[0];
    }
    return result || devices[0];
  }

  ngOnDestroy(): void {
    this.logger.close();
  }

  redirectToWindow() {
    const args = process.argv;
    const pathIdx = args.findIndex((arg: string) => arg.includes('--path='));
    let additionalArgs;
    if (pathIdx === -1) return;
    const path = args[pathIdx].split('=')[1];
    if (pathIdx !== args.length - 1) {
      additionalArgs = args.slice(pathIdx + 1);
    }
    this.router.navigateByUrl(`/${path}`, {
      state: {
        args: additionalArgs
      }
    });
  }
}
